import React from 'react'
import css from './Footer.module.css'
import Logo from '../../assets/logo.png'
import {
    InboxIcon,
    PhoneIcon,
    LocationMarkerIcon,
    LoginIcon,
    UsersIcon,
    LinkIcon
} from "@heroicons/react/outline";

const Footer = () => {
    return (
        <div className={css.cFooterWrapper}>
            <hr />
            <div className={css.cFooter}>
                <div className={css.logo}>
                    <img src={Logo} alt="" srcset="" />
                    <span>Amazon</span>
                </div>

                <div className={css.block}>
                    <div className={css.detail}>
                        <span>Contact Us</span>
                        <span className={css.pngLine}>
                            <LocationMarkerIcon className={css.icon} />
                            <span>111 north avenue Orlado, FL 32801</span>
                        </span>

                        <span className={css.pngLine}>
                            <PhoneIcon className={css.icon} />
                            <a href='tel:+250785283918'>tel:+250785283918</a>
                        </span>


                    </div>
                </div>



                <div className={css.block}>
                    <div className={css.detail}>
                        <span>Company</span>
                        <span className={css.pngLine}>
                            <UsersIcon className={css.icon} />
                            <a href="/about" className="h">
                                <p>About us</p>
                            </a>
                        </span>

                    </div>
                </div>

                <div className={css.block}>
                    <div className={css.detail}>
                        <span>Resources</span>
                        <span className={css.pngLine}>
                            <LinkIcon className={css.icon} />
                            <span>Safety provacy & Terms </span>
                        </span>

                    </div>
                </div>

            </div>
            <div className={css.copyRight}>
                <span>copyright @2022 by mazon, Inc.</span>
                <span>All Rights Reserved.</span>
            </div>

        </div>
    )
}

export default Footer